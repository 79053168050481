import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import { AnimatePresence } from 'framer-motion';
import './App.scss';

function App() {
	const [ allProjects, setAllProjects ] = useState([]);
	const location = useLocation();
	//
	// useEffect(() => {
	//     const fetchData = async () => {
	//         const db = getFirestore(firebaseApp);
	//
	//         const projectsRef = collection(db, 'projects');
	//         const snapshot = await getDocs(projectsRef);
	//         const arr = [];
	//         snapshot.forEach(doc => {
	//             arr.push(doc.data())
	//         });
	//
	//         setAllProjects(arr);
	//     };
	//
	//     fetchData();
	// }, []);

	const offlineData = [
        {
            title: 'Revised',
            description:
                'In opdracht van Revised heb ik de gehele website opnieuw ontwikkeld aan de hand van een vernieuwd design. De website maakt gebruik van WordPress en Woocommerce en is ontwikkeld aan de hand van een custom wordpress thema en het StoreFront parent Thema.',
            languages: 'HTML - SASS (CSS) - Javascript - WordPress - PHP',
            role: 'Full-Stack Dev',
            url: 'https://revised.com/',
            cover: 'https://jordeyknook.nl/img/revised-cover.jpg',
            client: 'Particulier',
            featured: true
        },
		{
			title: 'Rephone',
			description:
				'De website van Rephone was aan vernieuwing toe en heeft daarom een nieuw design gekregen. Hierin heb ik de taak gekregen om dit design te ontwikkelen met de hierbij bijbehorende jQuery functies.',
			languages: 'HTML - SASS (CSS) - jQuery',
			role: 'Front-end Dev',
			url: 'https://www.rephone.nl/',
			cover: 'https://jordeyknook.nl/img/rephone-cover.jpeg',
			client: 'Rephone',
			featured: true
		},
		{
			title: "ServiceRight Auto's",
			description:
				'Het ontwikkelen van de front-end voor het nieuwe WordPress thema dat ServiceRight gebruikt voor al haar andere services.',
			languages: 'HTML - SASS (CSS) - Javascript - jQuery',
			role: 'Front-end Dev',
			url: 'https://serviceright-autos.nl/',
			cover: 'https://jordeyknook.nl/img/sr-cover.jpeg',
			client: 'ServiceRight',
			featured: true
		},
        {
            title: 'Quodes',
            description:
                'In opdracht van Quodes heb ik de gehele website opnieuw ontwikkeld aan de hand van een vernieuwd design. De website maakt gebruik van WordPress en Woocommerce en is ontwikkeld aan de hand van een custom wordpress thema en het StoreFront parent Thema.',
            languages: 'HTML - SASS (CSS) - Javascript - WordPress - PHP',
            role: 'Full-Stack Dev',
            url: 'https://quodes.com/',
            cover: 'https://jordeyknook.nl/img/quodes-cover.jpg',
            client: 'Particulier',
            featured: false
        },
		{
			title: 'Kostretail',
			description:
				'Herontwikkeling van de nieuwe website met WordPress en Woocommerce. Gebaseerd op het StoreFront parent theme is de website opgebouwd met een custom wordpress thema. Wegens het grote success van de nieuwe website is deze ook toegepast bij het moeder bedrijf.',
			languages: 'HTML - SASS (CSS) - Javascript - WordPress - PHP',
			role: 'Full-Stack Dev',
			url: 'https://www.kostretail.com/cms/',
			cover: 'https://jordeyknook.nl/img/kostretail-cover.jpeg',
			client: 'Kost & 10Fashion',
			featured: false
		},
        {
            title: 'De Rijnhoven',
            description:
                'In samenwerking met Mediaschip ben ik verantwoordelijk voor de ontwikkeling van de front-end met de bijbehorende functionaliteiten.',
            languages: 'HTML - SASS (CSS) - Javascript',
            role: 'Front-end Dev',
            url: 'https://www.rijnhoven.nl/',
            cover: 'https://jordeyknook.nl/img/rijnhoven-cover.jpg',
            client: 'Mediaschip',
            featured: true
        },
        {
            title: 'PCB Creators',
            description:
                'In opdracht van PCB Creators ben ik verantwoordelijk voor de ontwikkeling van de front-end met de bijbehorende functionaliteiten.',
            languages: 'HTML - SASS (CSS) - Javascript',
            role: 'Front-end Dev',
            url: 'https://www.pcbcreators.com/nl-nl/',
            cover: 'https://jordeyknook.nl/img/pcb-cover.jpg',
            client: 'Particulier',
            featured: false
        },
		{
			title: 'Kahurangi Wine',
			description:
				'In samenwerking met NOKNOK Studios ben ik verantwoordelijk voor het ontwikkelen van de front-end met de bijbehorende functionaliteiten.',
			languages: 'HTML - SASS (CSS) - Javascript',
			role: 'Front-end Dev',
			url: 'https://kahurangiwine.com/',
			cover: 'https://jordeyknook.nl/img/kahurangiwine-cover.jpeg',
			client: 'NOKNOK Studios',
			featured: true
		},
		{
			title: 'SSL Consulting',
			description:
				'In samenwerking met NOKNOK Studios ben ik verantwoordelijk voor het ontwikkelen van de front-end met de bijbehorende functionaliteiten.',
			languages: 'HTML - SASS (CSS) - Javascript',
			role: 'Front-end Dev',
			url: 'https://ssl.consulting/',
			cover: 'https://jordeyknook.nl/img/ssl-consulting-cover.jpeg',
			client: 'NOKNOK Studios',
			featured: false
		},
		{
			title: 'Repost',
			description:
				'In samenwerking met NOKNOK Studios ben ik verantwoordelijk voor het ontwikkelen van de front-end met de bijbehorende functionaliteiten.',
			languages: 'HTML - SASS (CSS) - Javascript',
			role: 'Front-end Dev',
			url: 'https://repost.co.nz/',
			cover: 'https://jordeyknook.nl/img/repost-cover.jpeg',
			client: 'NOKNOK Studios',
			featured: true
		},
		{
			title: 'The Junction',
			description:
				'In samenwerking met NOKNOK Studios ben ik verantwoordelijk voor het ontwikkelen van de front-end met de bijbehorende functionaliteiten.',
			languages: [ 'HTML', 'SASS (CSS)', 'Javascript' ],
			role: 'Front-end Dev',
			url: 'https://thejunctionshop.co.nz/',
			cover: 'https://jordeyknook.nl/img/the_junction.jpg',
			client: 'NOKNOK Studios',
			featured: true
		},
		{
			title: 'BraxBot',
			description:
				'In samenwerking met Braxweb ben ik verantwoordelijk voor het ontwikkelen van de front-end met de bijbehorende functionaliteiten.',
			languages: [ 'HTML', 'SASS (CSS)', 'Javascript' ],
			role: 'Front-end Dev',
			url: 'https://braxbot.com/',
			cover: 'https://jordeyknook.nl/img/braxbot-cover.jpeg',
			client: 'Braxweb',
			featured: false
		},
		{
			title: 'Alet de Vries',
			description: 'Portfolio website, opgebouwd met WordPress aan de hand van een custom made WordPress thema.',
			languages: [ 'HTML', 'SASS (CSS)', 'Javascript' ],
			role: 'Front-end Dev',
			url: 'aletdevries.com/',
			cover: 'https://jordeyknook.nl/img/aletdevries-cover.png',
			client: 'Particulier',
			featured: false
		},
		{
			title: 'Fresch Management',
			description:
				'In samenwerking met Mediaschip heb ik gewerkt aan de ontwikkeling van de front-end met de bijbehorende functionaliteiten.',
			languages: 'HTML - SASS (CSS) - Javascript',
			role: 'Front-end Dev',
			url: 'https://www.fresch-management.nl/',
			cover: 'https://jordeyknook.nl/img/freschmanagement-cover.jpeg',
			client: 'Particulier',
			featured: false
		},
		// {
		//     'title': 'SET Interieur',
		//     'description': 'lorem tekst',
		//     'languages' : ['HTML', 'SASS (CSS)', 'Javascript', 'WordPress', 'PHP'],
		//     'role': 'Full-Stack Dev',
		//     'url': 'https://quodes.com/',
		//     'cover': 'https://jordeyknook.nl/img/kahurangiwine-cover.jpeg',
		//     'client': 'Mediaschip',
		//     'featured': false
		// },  // {
		//     'title': 'Erfelijke maagkanker',
		//     'description': 'lorem tekst',
		//     'languages' : ['HTML', 'SASS (CSS)', 'Javascript', 'WordPress', 'PHP'],
		//     'role': 'Full-Stack Dev',
		//     'url': 'https://www.erfelijkemaagkanker.nl/',
		//     'cover': 'https://jordeyknook.nl/img/kahurangiwine-cover.jpeg',
		//     'client': 'Mediaschip',
		//     'featured': false
		// },
		{
			title: "Denise's Choice",
			description:
				'In samenwerking met Mediaschip heb ik een grote rol gespeeld binnen de ontwikkeling van de front-end met de bijbehorende functionaliteiten.',
			languages: 'HTML - SASS (CSS) - Javascript',
			role: 'Front-end Dev',
			url: 'https://www.alcoholfreedrinks.nl/',
			cover: 'https://jordeyknook.nl/img/deniseschoice-cover.jpg',
			client: 'Mediaschip',
			featured: false
		},
		{
			title: 'Chefshopper',
			description:
				'In opdracht van Braxweb ben ik verantwoordelijk voor de ontwikkeling van de front-end met de bijbehorende functionaliteiten.',
			languages: 'HTML - SASS (CSS) - Javascript',
			role: 'Front-end Dev',
			url: 'https://chefshopper.co/',
			cover: 'https://jordeyknook.nl/img/chefshopper-cover.jpg',
			client: 'Braxweb',
			featured: false
		},
        {
            title: 'Cryptowiz web',
            description:
                'Cryptowiz is een cryptocurrency-app voor Android en IOS, Naast de ontwikkeling van de gehele website heb ik ook een bijdrage geleverd binnen de ontwikkeling van de app.',
            languages: 'HTML - SASS (CSS) - jQuery',
            role: 'Front-end Dev',
            url: 'https://cryptowiz.app/',
            cover: 'https://jordeyknook.nl/img/cryptowiz-cover.jpeg',
            client: 'Particulier',
            featured: false
        },
	];

	useEffect(() => {
		const archive = [];
		const featured = [];

		offlineData.forEach((item) => {
			item.featured ? featured.push(item) : archive.push(item);
		});

		// setAllProjects(offlineData);
		setAllProjects({ offlineData, featured, archive });
	}, []);

	return (
		<AnimatePresence exitbeforeenter>
			<Switch location={location} key={location.pathname}>
				<Route exact path={'/'}>
					<Home/>
				</Route>
				<Route path={'/projects'}>
					<Projects data={allProjects} />
				</Route>
				<Route path={'/about'}>
					<About />
				</Route>
			</Switch>
		</AnimatePresence>
	);
}

export default App;
