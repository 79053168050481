import React, {useState} from 'react';
import ProjectThumbnail from './ProjectThumbnail';

export default function ProjectList ({projects}) {
    const [seeArchive, setSeeArchive] = useState(false);
    const {offlineData} = projects;

    const showArchived = () => {
        if (seeArchive === true) {
            return (
                <>
                    {offlineData && offlineData.map((project, i) =>
                        project.featured === false ? <ProjectThumbnail key={`project_archive_${i}`} data={project} /> : null)
                    }
                </>
            )
        } else {
            return (
                <div className={'banner'}>
                    <div className="container">
                        <p className={'mb-3'}>Naast de uitgelichte projecten zijn er ook 9 andere projecten te zien.</p>
                        <p className={'btn'} onClick={() => setSeeArchive(seeArchive => !seeArchive)}>Meer projecten bekijken</p>
                    </div>
                </div>
            )
        }
    };

    return (
        <section className={'project-list page'}>
            {offlineData && offlineData.map((project, i) =>
                project.featured ? <ProjectThumbnail key={`project_${i}`} data={project}/> : null)
            }

            {showArchived()}
        </section>
    )
}