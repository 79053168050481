import React from 'react';
import {Link, NavLink} from "react-router-dom";

export default function NavBar () {
    return (
        <>
            <nav className={'main-nav'}>
                <div className="container">
                    <ul>
                        <li>
                            <Link to={'/'}>Home</Link>
                        </li>
                        <li>
                            <NavLink to={'/about'} activeClassName={'active'}>Over mij</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/projects'} activeClassName={'active'}>Projecten</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}