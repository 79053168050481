import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

export default function ProjectThumbnail({ data }) {
	const { url } = useRouteMatch();

	function formatTitle(title) {
		return title.replace(/\s+/g, '-').toLowerCase();
	}

	return (
		<div className={'project-thumbnail'} style={data && { backgroundImage: `url(${data.cover})` }}>
			{/* <p className={'counter'}>{id < 9 ? 0 : null}{id + 1}</p> */}

			<div className="container">
				<div className="content">
					<p className={'mb-3'}>{data.featured ? 'Uitgelicht' : 'Archived'} project</p>
					<h2 className={'mb-2'}>{data.title}</h2>
					<h3 className="mb-3 mb-lg-5">{data.role}</h3>
					<Link className={'btn'} to={{ pathname: `${url}/${formatTitle(data.title)}`, state: 'featured' }}>
						Bekijk Project
					</Link>
				</div>

				<div className="image">
					<img src={data.cover} alt={`${formatTitle(data.title)}_image`} />
				</div>
			</div>
		</div>
	);
}
