import React from 'react';
import {motion} from 'framer-motion';
import NavBar from "./NavBar";

export default function About() {
    return (
        <motion.section className={'page about'} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}>
            <NavBar />

            <div className="content">
                <div className="person mb-3">
                    <img src="https://jordeyknook.nl/img/wizard.png" alt="You're a wizard Jordey" className={'mb-4'}/>

                    <div className="info">
                        <p className={'mb-3'}>Hello there, ik ben Jordey.</p>

                        <div className="mail mb-3">
                            <i className="far fa-envelope mr-3"></i>
                            <p>jordey@webbey.nl</p>
                        </div>

                        <div className="socials">
                            <a href="https://github.com/jordeytje" target='_blank'><i className="fab fa-github-square mr-3"></i></a>
                            <a href="https://www.linkedin.com/in/jordey-knook/" target='_blank'><i className="fab fa-linkedin"></i></a>
                            <a href="https://jordeyknook.nl/jordey_knook_cv_2021.pdf" target='_blank' className={'ml-3 btn'}>Bekijk CV</a>
                        </div>
                    </div>
                </div>

                <p className="quote mb-3">Met een zwak voor panda's, een doel om overal googly eyes op te plakken, slechte humor en met een pennyboard onder mijn voeten ben ik klaar voor elke uitdaging.</p>
            </div>

            <div className="image">
                <div className="inner">
                    <img src="https://jordeyknook.nl/img/visuals-colored.png" alt="about" id={'about-me'}/>
                </div>
            </div>
        </motion.section>
    )
}