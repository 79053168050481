import React, {useState, useEffect} from 'react';
import { motion } from 'framer-motion';
import NavBar from './NavBar';

export default function Home() {
	const [wizard, setWizard] = useState(false);

	useEffect(() => {
        const img = document.getElementById('wizard-img');
        const target = document.querySelector('section.home');

		if (wizard) {
            target.classList.add('magic');
            img.classList.add('show');

            setTimeout(() => {
                img.classList.remove('show');
            }, 2300);

            setTimeout(() => {
                target.classList.remove('magic');
                setWizard(false);
			}, 3000)
		}
	},[wizard]);

	return (
		<motion.section
			className={'page home'}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opaciyt: 0 }}
			transition={{ duration: 0.5 }}
		>
            <div id="wizard-overlay"></div>

			<NavBar />

			<div className="container">
				<div className="content">
					<h3 className={'mb-3'}>Hey, ik ben Jordey</h3>
					<h1 className="mb-5">
						Creative Front end <br /> webdeveloper
					</h1>

					<p className={'mb-3'}>
						Ik ben iemand die websites of websystemen ontwikkelt en waar familieleden naartoe gaan om hun
						printer te fixen.
					</p>

					<p>Zie ook: <span onClick={() => setWizard(true)}>Goochelaar</span> of <span id="google"><span>G</span><span>o</span><span>o</span><span>g</span><span>l</span><span>e</span>laar</span>.</p>
				</div>
			</div>

            <img src="https://jordeyknook.nl/img/wizard.png" alt="You're a wizard Jordey" id={'wizard-img'}/>
		</motion.section>
	);
}
