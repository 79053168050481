import React from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import Project from './Project';
import { AnimatePresence, motion } from 'framer-motion';
import NavBar from './NavBar';
import ProjectList from './ProjectList';

export default function Projects({ data }) {
	let { path } = useRouteMatch();
	const location = useLocation();

	return (
		<motion.section
			className={'page projects'}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<NavBar />
			<AnimatePresence>
				<Switch location={location} key={location.pathname}>
					<Route exact path={path}>
						<ProjectList projects={data} />
					</Route>
					<Route path={`${path}/:projectId`}>
						<Project data={data} />
					</Route>
				</Switch>
			</AnimatePresence>
		</motion.section>
	);
}
