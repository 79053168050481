import React, {useEffect, useState} from 'react';
import {Link, Redirect, Route, useParams, useLocation, useHistory} from 'react-router-dom';
import {motion} from 'framer-motion';
import LanguagesList from "./LanguagesList";
import NavBar from "./NavBar";

export default function Project({data}) {
    let location = useLocation();
    const history = useHistory();
    let {projectId} = useParams();

    console.log(data);
    // const {offlineData, featured, archive} = data;

    const [projectData, setProjectData] = useState([]);
    const [currentPos, setCurrentPos] = useState([]);

    const [prevProject, setPrevProject] = useState(0);
    const [nextProject, setNextProject] = useState(0);

    useEffect(() => {
        const {offlineData, featured, archive} = data;

        // stop if undefined
        if (location.state === undefined || data.length === 0) {
            return <Redirect to='projects'/>;
        }

        // get entry from all projects
        let position = offlineData.findIndex(x => formatTitle(x.title) === projectId);
        offlineData[position].featured ? setProjectData(featured[position]) : setProjectData(archive[position]);

        //get and set entry from child array
        if (offlineData[position].featured) {
            let pos = featured.findIndex(x => formatTitle(x.title) === projectId);
            // console.log(pos);
            setProjectData(featured[pos]);
        } else {
            let pos = archive.findIndex(x => formatTitle(x.title) === projectId);
            setProjectData(archive[pos]);
        }

        setCurrentPos(position);
        setPrevProject(position - 1);
        setNextProject(position + 1);

    }, [data, currentPos]);

    function formatTitle(title) {
        return title.replace(/\s+/g, '-').toLowerCase();
    }

    function handleClick() {
        history.push('/projects');
    }

    // const projectCounter = () => {
    //     if (projectData && projectData.featured) {
    //         console.log(featuredData);
    //         return <p className={'mb-0'}>{currentPos < 10 && 0}{currentPos + 1} - {featuredData.length < 10 ? 0 : null}{featuredData.length}</p>
    //     } else {
    //         console.log(archivedData);
    //         return <p className={'mb-0'}>{currentPos < 10 && 0}{currentPos + 1} - {archivedData.length < 10 ? 0 : null}{archivedData.length}</p>
    //     }
    // };

    return (
        <>
            {projectData && location.state === undefined && projectData.length === 0 ? <Redirect to="/projects" /> : null}

            <section className={'page project'} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.5}}>
                <div className="content">
                    <div className="container">
                        <NavBar/>
                    </div>

                    <div className="about">
                        <div className="container">
                            <p className={'mb-5'} onClick={handleClick}>Terug</p>

                            {/*{projectCounter()}*/}

                            <p className="mb-2">{projectData && projectData.featured ? 'Uitgelicht' : 'Archief'}</p>
                            <h2 className={'mb-5'}>{projectData && projectData.title}</h2>
                            <p className="mb-4">
                                {projectData && projectData.description}
                            </p>

                            <div className="navigation">
                                {data[prevProject] && <Link to={`${formatTitle(data[prevProject].title)}`} onClick={() => setCurrentPos(prevProject)} className={"mr-3 btn"}>Vorige</Link>}
                                {data[nextProject] && <Link to={`${formatTitle(data[nextProject].title)}`} onClick={() => setCurrentPos(nextProject)} className={"btn"}>Volgende</Link>}
                            </div>
                        </div>
                    </div>

                    <div className="bottom-info">
                        <div className="container">
                            <div className="tech mt-3 mt-md-0">
                                <p className="mb-3"><b>Gebruikte technieken</b></p>
                                <p className="mb-0">{projectData && projectData.languages}</p>

                            </div>

                            <div className="info">
                                <p className="mb-3"><b>Rol:</b> {projectData && projectData.role}</p>
                                <p><span>In Samenwerking met:</span> {projectData && projectData.client}</p>
                            </div>
                        </div>

                        <div className="visit">
                            <a href={projectData && projectData.url} target="_blank" >Bekijk project</a>
                        </div>
                    </div>
                </div>

                <div className="image" style={projectData && {backgroundImage: `url(${projectData.cover})`}}>
                    <div className="container">
                        <h2>{projectData && projectData.title}</h2>
                    </div>
                </div>
            </section>
        </>
    )
}